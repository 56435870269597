import { AddIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function NewOrder() {
  return (
    <Stack p="2">
      <HStack justify={"space-between"}>
        <Text>Order Items</Text>
        <IconButton colorScheme={"cyan"} size="sm" icon={<AddIcon />} />
      </HStack>
    </Stack>
  );
}
