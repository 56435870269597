import { Center } from "@chakra-ui/react";
import React from "react";
import Loading from "../basic/Loading";

function EmptyChat() {
  return (
    <Center h="100%" w="100%">
      <Loading />
    </Center>
  );
}

export default EmptyChat;
