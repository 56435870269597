import { Center, Heading, HStack, Stack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { parse } from "vcard-parser/lib/vcard";
import { Avatar } from "@chakra-ui/avatar";
import { Skeleton } from "@chakra-ui/skeleton";
import { Button } from "@chakra-ui/button";
import { BsWhatsapp } from "react-icons/bs";
import { Spinner } from "@chakra-ui/spinner";
import axiosInstance from "../../utils/axios";

function VCard({ chat }) {
  const vcard = parse(chat.body);
  const [whatsapp, setWhatsApp] = useState(false);
  const [loading, setLoading] = useState(false);

  const url =
    process.env.REACT_APP_WHATSAPP_INSTANCE_URL ||
    "https://api.chat-api.com/instance334898/";
  const token = process.env.REACT_APP_WHATSAPP_TOKEN || "clnmpczxu5yowz3a";
  const MINUTE_MS = 30000;

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(
        url +
          "checkPhone?token=" +
          token +
          "&phone=" +
          vcard?.tel[0]?.meta?.waid[0]
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.result === "exists") {
          setWhatsApp(true);
        }
        setLoading(false);
        //
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Stack>
      {vcard && (
        <>
          <HStack
            px="3"
            borderBottomColor="gray.500"
            borderStyle="solid"
            minW="200px"
          >
            <Avatar size="sm" />
            <Stack spacing="1px">
              <Heading size="xs" mb="1">
                {vcard.fn[0].value}
              </Heading>
              {vcard.tel.map((tel, index) => (
                <Text key={index} fontSize="xs">
                  {tel.value}
                </Text>
              ))}
            </Stack>
          </HStack>
          {loading && (
            <Center>
              <Spinner color="green.300" size="sm" />
            </Center>
          )}
          {whatsapp && !loading && (
            <Button
              w="100%"
              size="xs"
              leftIcon={<BsWhatsapp />}
              colorScheme="green"
              variant="outline"
            >
              Message
            </Button>
          )}
        </>
      )}
    </Stack>
  );
}

export default VCard;
