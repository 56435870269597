import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React from "react";
import { BsPlayFill } from "react-icons/bs";
import VideoImageThumbnail from "react-video-thumbnail-image";
import ReactPlayer from "react-player";
import { useDisclosure } from "@chakra-ui/hooks";

function VideoPlayer({ chat }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box position="relative">
        <Image
          width={200}
          height={"200px"}
          borderRadius="lg"
          shadow="lg"
          src={process.env.PUBLIC_URL + "/videobg.png"}
        />
        <Flex
          h="100%"
          w="100%"
          top="0"
          position="absolute"
          align="center"
          justify="center"
          left="auto"
          color="white"
          opacity={0.5}
          cursor="pointer"
          _hover={{
            opacity: 1,
            background: "rgba(0,0,0,.4)",
            borderRadius: "5px",
          }}
          onClick={onOpen}
          transition="all 0.35s ease-in-out"
        >
          <BsPlayFill size="40px" />
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={useColorModeValue("gray.700", "gray.50")}>
            Video
          </ModalHeader>
          <ModalCloseButton color={useColorModeValue("gray.700", "gray.50")} />
          <ModalBody bg="gray.900" p="0">
            <ReactPlayer width="100%" controls={true} url={chat.body} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VideoPlayer;
