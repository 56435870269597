import { ChakraProvider, Switch } from "@chakra-ui/react";
import { Route, Router, Routes } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";
import theme from "./utils/chakra";
import { createBrowserHistory } from "history";
import NotFound from "./components/pages/NotFound";
import Login from "./components/pages/Login";
import ProtectedRoute from "./utils/AuthContext/ProtectedRoute";
import Landing from "./components/pages/Landing";
import { Provider } from "react-redux";
import { store } from "./utils/redux/store";
import { SocketProvider } from "./utils/sockets/useSocket";

function App() {
  const history = createBrowserHistory();
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme} resetCSS={true}>
        <AuthProvider>
          <SocketProvider>
            <Routes history={history}>
              <Route
                path="/*"
                element={<ProtectedRoute element={<Landing />} />}
              />
              {/* <ProtectedRoute path="/" exact element={Landing} />
            <ProtectedRoute path="/logout" exact element={Logout} /> */}
              {/* <ProtectedRoute path="/settings" exact component={Landing} /> */}
              {/* <Route path="/chat/:number" exact component={Landing} /> */}
              <Route path="/login" exact element={<Login />} />
              <Route element={NotFound} />
            </Routes>
          </SocketProvider>
        </AuthProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
