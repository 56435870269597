import { useColorModeValue } from "@chakra-ui/color-mode";
import { Image } from "@chakra-ui/image";
import { Box, Flex, Link, Stack, Text, HStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { BsCheck2, BsCheck2All, BsClock, BsReply } from "react-icons/bs";
import Player from "react-howler-player";
import { useDispatch } from "react-redux";

import { FaFileAudio, FaFileVideo, FaSpeakerDeck } from "react-icons/fa";

import { IconButton } from "@chakra-ui/button";
import { setReply } from "../../utils/redux/replySlice";
import VCard from "./VCard";
import VideoPlayer from "./VideoPlayer";
import { MdAudiotrack } from "react-icons/md";

function SingleChat({ chat }) {
  //console.log(chat);
  let dr = chat.from_me ? "right" : "left";
  const direction = {
    [dr]: chat.from_me ? "-8px" : "-8px",
  };
  const br = {
    [chat.from_me ? "borderTopRightRadius" : "borderTopLeftRadius"]: "0px",
  };

  const colorMode = useColorModeValue(false, true);
  const data = JSON.parse(chat.type_data);
  const [date, setDate] = useState();
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  const [istoday, setIsToday] = useState(false);
  const [isyesterday, setIsYesterDay] = useState(false);

  useEffect(() => {
    let ndate = new Date(chat.lastMessage ? parseInt(chat.time * 1000) : "now");
    setDate(ndate);
    if (
      yesterday.getDay() === ndate.getDay() &&
      yesterday.getMonth() === ndate.getMonth() &&
      yesterday.getFullYear() === ndate.getFullYear()
    ) {
      let yesterday = true;
      setIsToday(false);
      setIsYesterDay(true);
    } else if (
      today.getDay() === ndate.getDay() &&
      today.getMonth() === ndate.getMonth() &&
      today.getFullYear() === ndate.getFullYear()
    ) {
      let yesterday = false;
      setIsToday(true);
    } else {
      let istoday = false;
    }
  }, [chat]);

  const width = () => {
    if (chat.type === "video") {
      return "300px";
    } else if (chat.type === "image") {
      return "300px";
    } else {
      return "65%";
    }
  };

  const dispatch = useDispatch();

  //console.log(direction);
  return (
    <>
      <Flex
        px="8"
        className={chat.from_me ? "right-bubble" : "left-bubble"}
        justify={chat.from_me ? "end" : "start"}
      >
        <Stack
          w="auto"
          maxW={width}
          minW="200px"
          spacing="5px"
          position="relative"
          role="group"
        >
          <IconButton
            zIndex="2"
            top="2"
            right="1"
            size="xs"
            colorScheme="yellow"
            visibility="hidden"
            transition="all 0.3s ease-in-out"
            _groupHover={{ visibility: "visible" }}
            children={<BsReply />}
            position="absolute"
            onClick={() => {
              dispatch(setReply(chat));
            }}
          />
          <Box
            p="1"
            bg={useColorModeValue(
              chat.from_me ? "primary.700" : "white",
              chat.from_me ? "primary.600" : "gray.700"
            )}
            borderRadius="5px"
            {...br}
            position="relative"
            shadow="sm"
          >
            <Box
              position="absolute"
              color={useColorModeValue(
                chat.from_me ? "primary.700" : "white",
                chat.from_me ? "primary.600" : "gray.700"
              )}
              {...direction}
              // left="-8px"
              top="0"
            >
              {chat.from_me ? (
                <svg viewBox="0 0 8 13" width="8" height="13">
                  <path
                    opacity=".13"
                    d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
                  ></path>
                </svg>
              ) : (
                <svg viewBox="0 0 8 13" width="8" height="13">
                  <path
                    opacity=".13"
                    fill="currentColor"
                    d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
                  ></path>
                </svg>
              )}
            </Box>
            <Stack
              color={useColorModeValue(
                chat.from_me ? "gray.50" : "gray.700",
                chat.from_me ? "white" : "gray.50"
              )}
            >
              {data.quotedMsgId && data.quotedMsgId.length > 0 && (
                <Stack
                  bg={colorMode ? "gray.900" : "gray.100"}
                  p="2"
                  borderRadius="5px"
                  borderLeftWidth="4px"
                  borderLeftColor="red.400"
                  spacing="3px"
                  color="gray.500"
                  display={
                    data.quotedMsgId && data.quotedMsgId.length > 0
                      ? "block"
                      : "none"
                  }
                >
                  {data.quotedMsgType === "chat" && (
                    <Text fontSize="0.7em" color={"gray.500"}>
                      {data.quotedMsgBody}
                    </Text>
                  )}
                  {data.quotedMsgType === "video" && <FaFileVideo />}
                  {data.quotedMsgType === "audio" && (
                    <HStack>
                      <MdAudiotrack />
                      <Text>Audio</Text>
                    </HStack>
                  )}
                </Stack>
              )}

              {chat.type === "image" && (
                <Image
                  maxW="300px"
                  borderRadius="5px"
                  src={chat.body}
                  fallbackSrc=""
                />
              )}

              {chat.type === "location" && (
                <Link
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${chat.body.replace(
                    ";",
                    ","
                  )}`}
                >
                  <Image
                    src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(%7B%22type%22:%22Point%22,%22coordinates%22:[${
                      chat.body.split(";")[1]
                    },${chat.body.split(";")[0]}]%7D)/${
                      chat.body.split(";")[1]
                    },${
                      chat.body.split(";")[0]
                    },12/500x300?access_token=pk.eyJ1IjoiaWFuYWxlY2siLCJhIjoiY2t2eGIzeXl2MDNsZDJ2bm93bDFhNzZ0eSJ9.yvw9FPfRGD0NIrOaPoEO8g`}
                    maxW="300px"
                    borderRadius="md"
                  />
                </Link>
              )}
              <Text
                p="1"
                fontSize="0.90em"
                lineHeight="1.3"
                style={{ whiteSpace: "pre-line" }}
                color={useColorModeValue(
                  chat.from_me ? "gray.50" : "gray.700",
                  chat.from_me ? "white" : "gray.50"
                )}
              >
                {/* {chat.agent_name}  */}
                {(chat.type === "chat" || chat.type === "note") && chat.body}
                {chat.type === "image" && JSON.parse(chat.type_data).caption}
                {chat.type === "location" && JSON.parse(chat.type_data).caption}
              </Text>

              {(chat.type === "ptt" || chat.type === "audio") && (
                <Player
                  style={{ boxShadow: "none" }}
                  src={[chat.body]}
                  isDark={colorMode}
                />
              )}

              {chat.type === "video" && chat.body && (
                <VideoPlayer islight={!colorMode} chat={chat} />
              )}
              {chat.type === "vcard" && <VCard chat={chat} />}

              <Flex justify="end">
                <Text
                  fontSize="0.70em"
                  color={useColorModeValue(
                    chat.from_me ? "gray.50" : "gray.600",
                    chat.from_me ? "white" : "gray.400"
                  )}
                >
                  {chat.created
                    .substring(2, 16)
                    .replace("T", " ")
                    .replaceAll("-", "/")}
                </Text>
              </Flex>
            </Stack>
          </Box>
          {chat.from_me && (
            <Flex justify="end" color="gray.500">
              {chat.status === "sent" && <BsCheck2 />}
              {chat.status === "delivered" && <BsCheck2All />}
              {chat.status === "viewed" && <BsCheck2All color="#0095ff" />}
              {chat.status === "sending" && <BsClock />}
            </Flex>
          )}
        </Stack>
      </Flex>
    </>
  );
}

export default SingleChat;
