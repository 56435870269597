import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from ".";

export default function ProtectedRoute({ element }) {
  const { currentUser } = useAuth();
  const { pathname } = useLocation();

  console.log(pathname);

  return currentUser ? (
    element
  ) : pathname !== "/" ? (
    <Navigate to={`/login?redirect=${pathname}`} />
  ) : (
    <Navigate to={`/login`} />
  );
}
