import { configureStore } from "@reduxjs/toolkit";
import poolSlice from "./poolSlice";
import replySlice from "./replySlice";
import userSlice from "./userSlice";

export const store = configureStore({
  reducer: {
    pool: poolSlice,
    reply: replySlice,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
