import {
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
import React, { useState } from "react";
import ChatItem from "./ChatItem";

function ChatListView({ chats }) {
  const [loading] = useState(false);
  const [phone, setPhone] = useState("");
  return (
    <>
      <HStack p={3} position="sticky" top="0" zIndex="1">
        <InputGroup>
          <Input
            bg={useColorModeValue("gray.50", "gray.700")}
            color={useColorModeValue("gray.700", "gray.50")}
            placeholder="Search Phone / Name"
            borderRadius="20px"
            shadow="sm"
            fontSize="0.8em"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            borderColor={useColorModeValue("gray.300", "gray.500")}
          />
          {phone.length > 9 && (
            <InputRightElement
              cursor="pointer"
              color="green.300"
              children={<MdSend />}
            />
          )}
          {loading && (
            <InputRightElement
              cursor="pointer"
              color="gray.300"
              children={<Spinner />}
            />
          )}
        </InputGroup>
      </HStack>
      {chats.length > 0 &&
        chats.map((chat) => (
          <ChatItem setPhone={setPhone} key={chat.phone} chat={chat} />
        ))}
    </>
  );
}

export default ChatListView;
