import {
  HStack,
  Spinner,
  Square,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { ImExit } from "react-icons/im";
import {
  BsChatLeft,
  BsTruck,
  BsShare,
  BsFillSunFill,
  BsMoonFill,
  BsCashCoin,
  BsGear,
} from "react-icons/bs";
import { BiMenu } from "react-icons/bi";
import { FiShoppingBag } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { useAuth } from "../../utils/AuthContext";
import Home from "./Home";
import Chat from "./Chat";
import { SocketProvider, useSocket } from "../../utils/sockets/useSocket";
import { useSelector } from "react-redux";
import Loading from "../basic/Loading";

function Landing() {
  const { logout } = useAuth();
  let { pathname } = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();
  const [loading, setLoading] = useState(false);

  const pool = useSelector((state) => state.pool.value);

  console.log("pool", pool);

  const { socket, connected, connect, disconnect } = useSocket();

  useEffect(() => {
    connect();

    return () => {
      disconnect();
    };
  }, []);

  return (
    <HStack
      h="100vh"
      bg={useColorModeValue("gray.50", "primary.100")}
      justify="start"
      align="start"
      spacing="0px"
    >
      {/* Left Side Bar */}
      <Stack
        w="60px"
        bg={useColorModeValue("primary.100", "primary.100")}
        h="100%"
        shadow="md"
        spacing="1px"
      >
        <Square
          cursor="pointer"
          size="60px"
          bg={useColorModeValue("gray.800", "gray.100")}
          color={useColorModeValue("gray.50", "primary.100")}
          _hover={{
            bg: useColorModeValue("gray.100", "primary.100"),
            color: useColorModeValue("primary.100", "gray.50"),
          }}
        >
          <BiMenu size="25px" />
        </Square>
        <Link to="/">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname === "/" && "gray.50",
              pathname === "/" && "primary.100"
            )}
            color={useColorModeValue(
              pathname === "/" ? "primary.100" : "gray.50",
              pathname === "/" ? "white" : "gray.50"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "primary.100"),
              color: useColorModeValue("primary.100", "gray.50"),
            }}
          >
            <GoHome size="25px" />
          </Square>
        </Link>
        <Link to="/chat">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/chat") && "gray.50",
              pathname.startsWith("/chat") && "primary.100"
            )}
            color={useColorModeValue(
              pathname.startsWith("/chat") ? "primary.100" : "gray.100",
              pathname.startsWith("/chat") ? "white" : "gray.100"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "primary.100"),
              color: useColorModeValue("primary.100", "gray.50"),
            }}
            position="relative"
          >
            <BsChatLeft size="25px" />
            {loading && (
              <Spinner
                color="red.400"
                top="10px"
                right="10px"
                h="15px"
                w="15px"
                position="absolute"
              />
            )}
            {!loading && (
              <Text
                fontSize="10px"
                p="1"
                bg={"#05F200"}
                color="gray.900"
                borderRadius="10px"
                position="absolute"
                top="10px"
                right="10px"
                shadow="md"
                visibility={
                  pool.unread + pool.incomingChats.length === 0 && "hidden"
                }
              >
                {pool.unread}
              </Text>
            )}
          </Square>
        </Link>
        <Link to="/orders">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname.startsWith("/orders") && "gray.50",
              pathname.startsWith("/orders") && "primary.100"
            )}
            color={useColorModeValue(
              pathname.startsWith("/orders") ? "primary.100" : "gray.100",
              pathname.startsWith("/orders") ? "white" : "gray.100"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "primary.100"),
              color: useColorModeValue("primary.100", "gray.50"),
            }}
            position="relative"
          >
            <FiShoppingBag size="25px" />
            {loading && (
              <Spinner
                color="red.400"
                top="10px"
                right="10px"
                h="15px"
                w="15px"
                position="absolute"
              />
            )}
            {!loading && (
              <Text
                fontSize="10px"
                p="1"
                bg={"gray.800"}
                color="gray.100"
                borderRadius="10px"
                position="absolute"
                top="10px"
                right="10px"
                shadow="md"
                // visibility={
                //   pool.unread + pool.incomingChats.length === 0 && "hidden"
                // }
              >
                20
              </Text>
            )}
          </Square>
        </Link>
        <Link to="/settings">
          <Square
            size="60px"
            bg={useColorModeValue(
              pathname === "/settings" && "gray.50",
              pathname === "/settings" && "primary.100"
            )}
            color={useColorModeValue(
              pathname === "/settings" ? "primary.100" : "gray.50",
              pathname === "/settings" ? "white" : "primary.100"
            )}
            _hover={{
              bg: useColorModeValue("gray.100", "primary.100"),
              color: useColorModeValue("primary.100", "gray.50"),
            }}
          >
            <BsGear size="25px" />
          </Square>
        </Link>

        <Square
          cursor="pointer"
          onClick={logout}
          size="60px"
          bg={useColorModeValue("primary.100", "gray.100")}
          color={useColorModeValue("gray.50", "primary.100")}
          _hover={{
            bg: useColorModeValue("gray.100", "primary.100"),
            color: useColorModeValue("primary.100", "gray.50"),
          }}
        >
          <ImExit size="25px" />
        </Square>
      </Stack>
      {/* Right Side Application */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat/*" element={<Chat />} />
        <Route path="/orders/*" element={<Loading />} />
        <Route path="/settings/*" element={<Loading />} />
      </Routes>
    </HStack>
  );
}

export default Landing;
