import { HStack } from "@chakra-ui/react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ChatContainer from "../chat/ChatContainer";
import ChatList from "../chat/ChatList";
import EmptyChat from "../chat/EmptyChat";

function Chat() {
  return (
    <HStack h="100%" align="start" justify="start" w="100%" spacing="0">
      <ChatList />
      <Routes>
        <Route index element={<EmptyChat />} />

        <Route path="/:phone" element={<ChatContainer />} />
      </Routes>
    </HStack>
  );
}

export default Chat;
