import {
  Center,
  Circle,
  HStack,
  Image,
  Square,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { BsChatLeft, BsInbox, BsPeople } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useAuth } from "../../utils/AuthContext";
import ChatListView from "./ChatListView";

function ChatList() {
  const { currentUser } = useAuth();
  const pool = useSelector((state) => state.pool.value);
  return (
    <Stack
      bg={useColorModeValue("gray.100", "gray.800")}
      spacing="0"
      w="300px"
      minW="300px"
      h="100%"
      overflowY="scroll"
    >
      <HStack p="2" align="center">
        <Image src={process.env.PUBLIC_URL + "/logo.png"} maxW="5em" />
        <Stack flexGrow="1" textAlign="right" spacing="0">
          <Text fontSize="xl" fontWeight="thin" color="primary.100">
            Hello
          </Text>

          <Text fontWeight="light" fontSize="xs" color="#515151">
            {currentUser.name ? currentUser.name : currentUser.email}
          </Text>
        </Stack>
      </HStack>
      <Tabs isFitted p="2" variant="unstyled">
        <TabList>
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            bg="white"
            borderLeftRadius="0.3em"
            shadow="md"
          >
            <HStack>
              <Text fontSize="0.8em">ACTIVE</Text>
              <Circle
                bg="gray.900"
                w="1.4em"
                h="1.4em"
                color="white"
                shadow="sm"
              >
                <Center>
                  <Text fontSize="0.6em">{pool.activeChats.length}</Text>
                </Center>
              </Circle>
            </HStack>
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            borderRightRadius="0.3em"
            shadow="md"
            bg="white"
          >
            <HStack>
              <Text fontSize="0.8em">INCOMING</Text>
              <Circle
                color="white"
                bg="gray.900"
                w="1.4em"
                h="1.4em"
                shadow="sm"
              >
                <Center>
                  <Text fontSize="0.6em">{pool.incomingChats.length}</Text>
                </Center>
              </Circle>
            </HStack>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel h="100%" p={0}>
            <ChatListView chats={pool.activeChats} />
          </TabPanel>
          <TabPanel h="100%" p={0}>
            <ChatListView chats={pool.incomingChats} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default ChatList;
