import { Center, Spinner, Stack, Text } from "@chakra-ui/react";
import React from "react";

export default function Loading() {
  return (
    <Center h="100%" w="100%">
      <Stack align="center">
        <Text>Section Deactivated</Text>
        <Spinner />
      </Stack>
    </Center>
  );
}
