import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/axios";

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

export default function EditCustomer() {
  const { phone } = useParams();
  const [loading, setLoading] = useState();
  const [customer, setCustomer] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const fetchCustomer = async () => {
    setLoading(true);
    axiosInstance
      .get("/api/customer/" + phone)
      .then((res) => res.data)
      .then((data) => {
        setCustomer(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const saveCustomer = () => {
    setLoading(true);
    axiosInstance
      .put("/api/customer/" + customer.id, {
        name: customer.name,
        surname: customer.surname,
        email: customer.email,
        sex: customer.sex,
        dob: customer.dob,
      })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        if ("success" in data) {
          onClose();
          toast({
            title: "Success",
            description: "Customer updated successfully",
            status: "success",
          });
          return;
        }

        toast({
          title: "Error",
          description: "Customer not updated",
          status: "error",
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Error",
          description: "Customer not updated",
          status: "error",
        });
      });
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize={"sm"}>
            Edit {customer && customer.phone.replace("@c.us", "")}
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="7">
              <FormControl>
                <FormLabel fontSize={"0.8em"}>First Name</FormLabel>
                <Input
                  value={customer && customer.name}
                  onChange={(e) =>
                    setCustomer({ ...customer, name: e.target.value })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={"0.8em"}>Last Name</FormLabel>
                <Input
                  value={customer && customer.surname}
                  onChange={(e) =>
                    setCustomer({ ...customer, surname: e.target.value })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={"0.8em"}>Email</FormLabel>
                <Input
                  value={customer && customer.email}
                  onChange={(e) =>
                    setCustomer({ ...customer, email: e.target.value })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={"0.8em"}>Sex</FormLabel>
                <Select
                  placeholder="Select Gender"
                  value={customer && customer.sex}
                  onChange={(e) =>
                    setCustomer({ ...customer, sex: e.target.value })
                  }
                >
                  <option value="F">Female</option>
                  <option value="M">Male</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel fontSize={"0.8em"}>DOB</FormLabel>
                <Input
                  value={customer && customer.dob}
                  placeholder={"YYYY/MM/DD"}
                  onChange={(e) =>
                    setCustomer({ ...customer, dob: e.target.value })
                  }
                />
              </FormControl>

              <Box>
                <Button onClick={saveCustomer}>Submit</Button>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <HStack
        bg="cyan.800"
        shadow="lg"
        mx="2"
        p="2"
        borderBottomRadius={5}
        align="center"
      >
        <Avatar name={"No Name"} />
        <Stack flexGrow="1" color="white">
          <Text>
            {customer && (customer.name || customer.phone.replace("@c.us", ""))}
          </Text>
          <HStack fontSize={"0.8em"}>
            <Text>{customer && customer.phone.replace("@c.us", "")}</Text>
          </HStack>
        </Stack>
        <IconButton
          size="sm"
          bg="transparent"
          color="white"
          border="#ededed 1px solid"
          _hover={{ bg: "gray.900" }}
          icon={<BsPencilSquare />}
          onClick={onOpen}
        />
      </HStack>
    </>
  );
}
