import {
  Center,
  Circle,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";
import EditCustomer from "./EditCustomer";
import NewOrder from "./NewOrder";

export default function ChatActions() {
  return (
    <Stack>
      <EditCustomer />
      <Tabs isFitted p="2" variant="unstyled">
        <TabList>
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            bg="white"
            borderLeftRadius="0.3em"
            shadow="md"
          >
            <Stack>
              <HStack>
                <Text fontSize="0.8em">New Order</Text>
                <Circle
                  bg="gray.900"
                  w="1.4em"
                  h="1.4em"
                  color="white"
                  shadow="sm"
                >
                  <Center>
                    <Text fontSize="0.6em">0</Text>
                  </Center>
                </Circle>
              </HStack>
            </Stack>
          </Tab>
          <Tab
            _selected={{ color: "white", bg: "gray.700" }}
            borderRightRadius="0.3em"
            shadow="md"
            bg="white"
          >
            <HStack>
              <Text fontSize="0.8em">Orders</Text>
              <Circle
                color="white"
                bg="gray.900"
                w="1.4em"
                h="1.4em"
                shadow="sm"
              >
                <Center>
                  <Text fontSize="0.6em">0</Text>
                </Center>
              </Circle>
            </HStack>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel h="100%" p={0}>
            <NewOrder />
          </TabPanel>
          <TabPanel h="100%" p={0}>
            {/* <ChatListView chats={pool.incomingChats} /> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}
