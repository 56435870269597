import {
  Box,
  Center,
  Circle,
  Grid,
  Image,
  Spinner,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
import axiosInstance from "../../utils/axios";
import { useSocket } from "../../utils/sockets/useSocket";
import Loading from "../basic/Loading";
import ChatActions from "./ChatActions";
import SendBox from "./send/SendBox";
import SingleChat from "./SingleChat";

function ChatContainer() {
  const messagesEndRef = useRef();
  const messagesTopRef = useRef();
  const pool = useSelector((state) => state.pool.value);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [joined, setJoined] = useState(false);
  const { phone } = useParams();
  const [hasPrev, setHasPrev] = useState(false);
  const [cursor, setCursor] = useState(null);

  const [tags, setTags] = useState(["fridge"]);
  const user = useSelector((state) => state.user.value);
  const { currentUser } = useAuth();
  console.log(user);
  const { socket } = useSocket();

  const readMessage = (message) => {
    console.log(message);
    axiosInstance.get("/api/read/" + message.id);
    // setMessages([...messages, message]);
  };
  const addMessage = (msg) =>
    setMessages((prevMessages) => [...prevMessages, msg]);

  const handleScroll = (e) => {
    // if (!hasPrev) return;
    if (e.target.scrollTop === 0 && hasPrev && cursor) {
      setLoading(true);
      axiosInstance
        .get("/chat/chats/" + phone + "/" + cursor)
        .then((res) => res.data)
        .then((data) => {
          if (data?.messages) {
            setMessages([...data.messages, ...messages]);
            //messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            setLoading(false);
            setHasPrev(data.hasPrev);
            //subscribeToChat();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      //loard more messages
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!joined) {
    }
    axiosInstance
      .get("/chat/chats/" + phone)
      .then((res) => res.data)
      .then((data) => {
        if (data?.messages) {
          setMessages(data.messages);
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          setLoading(false);
          setHasPrev(data.hasPrev);
          setCursor(data.cursor);
          readMessage(data.messages[data.messages.length - 1]);
          //subscribeToChat();
        }
        socket.emit(
          "joinChat",
          phone.replace("@c.us", ""),
          currentUser.uid,
          (data) => {
            console.log(data);
            console.log("JoinChat");
            setJoined(true);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        );

        socket.on("chat", (message) => {
          console.log("chat", message);
          addMessage(message);
          messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          readMessage(message);
        });

        socket.on("update", (message) => {
          console.log("update", message);
          // let oldMessages = [...messages];
          // //filter through oldMessages and update the message where the id matches
          // let newMessages = oldMessages.map((m) => {
          //   if (m.api_message_id === message.id) {
          //     m.status = message.status;
          //     return m;
          //   }
          //   return m;
          // });

          // setMessages(newMessages);

          // addMessage(message);
          // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
          // readMessage(message);
        });

        // socket.on("chat", (message) => {
        //   concattinatemessages(message);
        //   console.log("chat", message);
        //   console.log(messages);
        //   console.log([message, ...messages]);
        // });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    return () => {
      if (joined && user) {
        socket.emit(
          "leave",
          {
            client: phone.replace("@c.us", ""),
            agent: user && (user.displayName || user.email),
          },
          (data) => {
            console.log("left chat");
          }
        );
      }
    };
  }, [phone]);

  return (
    <Grid h="100vh" w="100%" templateColumns={"8fr 4fr"}>
      <Stack
        // bg={useColorModeValue("#3e3e3e", "transaparent")}
        bg="rgb(255 250 245 / 94%)"
        backgroundAttachment={"fixed"}
        // bgImage={"}
        h="100%"
        w="100%"
        overflowY="scroll"
        overflowX="hidden"
        position={"relative"}
        // maxW="1000px"
        onScroll={handleScroll}
      >
        <img
          className="demo-bg"
          style={{
            opacity: 0.05,
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={process.env.PUBLIC_URL + "/chatbg-light.png"}
          alt=""
        ></img>
        <Stack p="0" pb={5} flexGrow="1" spacing="20px" position="relative">
          {/* <ChatTopInfo phone={phone} /> */}

          <Center display={loading ? "flex" : "none"}>
            <Circle bg={useColorModeValue("gray.100", "gray.700")} p="1">
              <Spinner color={useColorModeValue("red.500", "red.300")} />
            </Circle>
          </Center>
          {hasPrev && <Box w="100%" ref={messagesTopRef}></Box>}

          <Box
            style={{ marginTop: "0 !important" }}
            backgroundImage={`url(${
              process.env.PUBLIC_URL
            }/chatbg-${useColorModeValue("light", "dark")}.png)`}
          ></Box>
          {messages.map((message, index) => (
            <SingleChat key={index} chat={message} />
          ))}
          <Box ref={messagesEndRef}></Box>
        </Stack>
        <SendBox phone={phone} addMessage={addMessage} />
      </Stack>
      <Stack
        bg={useColorModeValue("gray.100", "gray.800")}
        h="100%"
        w="100%"
        overflowY="scroll"
        overflowX="hidden"
        spacing="40px"
      >
        <ChatActions />
      </Stack>
    </Grid>
  );
}

export default ChatContainer;
